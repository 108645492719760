<template>
  <b-sidebar
    id="opened-modal-form"
    :visible="openedModalForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-modal-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          v-if="item.id === undefined"
          class="mb-0"
        >
          {{ $t('admin.buttons.create') }}
        </h5>
        <h5
          v-else
          class="mb-0"
        >
          {{ $t('admin.buttons.edit') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <!-- Form -->
      <b-form class="p-2">

        <b-form-group
          :label="$t('admin.labels.code')"
          label-for="code"
        >
          <b-form-input
            id="code"
            v-model="item.code"
            :disabled="item.id !== undefined"
            :state="errors && errors.code ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.code">
            {{ errors.code[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('admin.labels.title')"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="item.title"
            :state="errors && errors.title ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.title">
            {{ errors.title[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('admin.labels.status')"
          label-for="status"
          :state="errors && errors.status ? false : null"
        >
          <v-select
            v-model="item.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :reduce="val => val.value"
            :clearable="false"
            input-id="status"
          />

          <b-form-invalid-feedback v-if="errors && errors.status">
            {{ errors.status[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          :label="$t('admin.labels.position')"
          label-for="position"
        >
          <b-form-input
            id="position"
            v-model="item.position"
            :state="errors && errors.position ? false : null"
          />

          <b-form-invalid-feedback v-if="errors && errors.position">
            {{ errors.position[0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="onSubmit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            {{ $t('admin.buttons.cancel') }}
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import StatusMixin from '@/mixins/StatusMixin'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [StatusMixin],
  model: {
    prop: 'openedModalForm',
    event: 'update:opened-modal-form',
  },
  props: {
    openedModalForm: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-modal-form', false)
    },
    onSubmit() {
      if (this.item.id === undefined) {
        this.$http.post('api/languages', this.item)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:opened-modal-form', false)
            this.$store.dispatch('validation/clearErrors')
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.$http.put(`/api/languages/${this.item.id}`, this.item)
          .then(() => {
            this.$emit('refetch-data')
            this.$emit('update:opened-modal-form', false)
            this.$store.dispatch('validation/clearErrors')
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-modal-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
